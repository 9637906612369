import React from 'react'
import { Link } from 'react-router-dom'
import './assets/scss/MobileNavbar.scss'

import Home from './assets/img/icons/home.png'
import Object from './assets/img/icons/3d.png'
import Cards from './assets/img/icons/poker-cards.png'
import News from './assets/img/icons/megaphone.png'
import Dice from './assets/img/icons/dice.png'


export default function Navbar() {

    return(

    <div className='mobile-navbar'>
      <div className="container-fluid">
                <div>
                  <ul className='mobile-navbar-ul'>
                  <Link to="/object"><img src={Cards} alt="Object" /></Link>
                  <Link to="/items"><img src={Object} alt="Items" /></Link>
                  <Link to="/"><img src={Home} alt="Home" /></Link>
                  <Link to="/actu"><img src={News} alt="Actu" /></Link>
                  <Link to="/random"><img src={Dice} alt="Random" /></Link>
                  </ul>
                </div>
              </div>
    </div>
    )
}