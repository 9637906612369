import React, { useContext, useState } from 'react';
import { ImageContext } from '../ImageProvider';

import './Random.scss'


export default function Aleatoire() {
  const images = useContext(ImageContext);

  const objets = [
	{nom : "EMF", image: images.emf2},
	{nom : "Spirit box", image: images.spiritbox2},
	{nom : "Livre", image: images.book2},
	{nom : "UV", image: images.uv2},
	{nom : "Caméra", image: images.camera2},
	{nom : "Capteur sonor", image: images.sonor2},
	{nom : "Capteur de mouvement", image: images.movement2},
	{nom : "D.O.T.S", image: images.dots2},
	{nom : "Pillules", image: images.pillule2},
	{nom : "Encens", image: images.encens2},
	{nom : "Bougie", image: images.candle2},
	{nom : "Parabole", image: images.parabol2},
	{nom : "Torche", image: images.torche2},
	{nom : "Sel", image: images.sel2},
	{nom : "Trépied", image: images.trepied2},
	{nom : "Thermomètre", image: images.thermo2},
	{nom : "Briquet", image: images.flint2},
	{nom : "Crucifix", image: images.crucifix2},
	{nom : "photo", image: images.photo2}
  ]

  const Tiers = [
	"Tier 1",
	"Tier 2",
	"Tier 3"
  ];

  const [joueurs, setJoueurs] = useState([]);

  const [nombreJoueurs, setNumbPlayers] = useState(0);

  const [objetsAleatoires, setObjetsAleatoires] = useState([]);

  const [tiersJoueurs, setTiersJoueurs] = useState([]);

  function repartirObjets(nombreJoueurs, objetsAleatoires) {

    // Création d'un tableau pour représenter les joueurs et leurs objets
    const joueurs = [];
    for (let i = 0; i < nombreJoueurs; i++) {
      joueurs.push([]);
    }

    // Attribution des objets à chaque joueur
    for (let i = 0; i < objetsAleatoires.length; i++) {
      const joueur = joueurs[i % nombreJoueurs];
      const objet = { ...objetsAleatoires[i], tier: Tiers[Math.floor(Math.random() * Tiers.length)] };
      joueur.push(objet);
    }

    // Attribuer un tier à chaque joueur
    const nouveauxTiers = [];
    for (let i = 0; i < nombreJoueurs; i++) {
      nouveauxTiers.push(Tiers[Math.floor(Math.random() * Tiers.length)]);
    }
    setTiersJoueurs(nouveauxTiers);
  
    console.log(joueurs);
    return joueurs;
    
  }

  function obtenirObjetsAleatoires(objectValue) {
    const objetsUniques = new Set();

    while (objetsUniques.size < objectValue) {
      const randomObject = objets[Math.floor(Math.random() * objets.length)];
      objetsUniques.add(randomObject);
    }

    setObjetsAleatoires(Array.from(objetsUniques));
    
  }
    
  return (
    <div className='random3'>
    <select id='nombrePlayers' className='btn btn-aleatoire' onChange={(e) => setNumbPlayers(parseInt(e.target.value))}>
      <option value="" disabled selected>Sélectionner le nombres de joueurs</option>
      <option value={1}>1 Joueur</option>
      <option value={2}>2 joueurs</option>
      <option value={3}>3 joueurs</option>
      <option value={4}>4 joueurs</option>
    </select>
    <select id="boutonObjetsAleatoires" className="btn btn-aleatoire" onChange={(event) => {
      const nombreObjets = parseInt(event.target.value) * nombreJoueurs;
      obtenirObjetsAleatoires(nombreObjets);
    }}>
      <option value="" disabled selected>Sélectionner le nombre d'objets</option>
      <option value={1}>Invoquer 1 objet</option>
      <option value={2}>Invoquer 2 objets</option>
      <option value={3}>Invoquer 3 objets</option>
      <option value={4}>Invoquer 4 objets</option>
    </select>

    <button className='btn' onClick={() => {
                    const nouveauxJoueurs = repartirObjets(nombreJoueurs, objetsAleatoires);
                    setJoueurs(nouveauxJoueurs);
                }}>
                    Répartir les objets
                </button>

                <div className='containerObject'>
                    {joueurs.map((joueur, index) => (
                      <div key={index} className=''>
                        <h2>Joueur {index + 1} ({joueur[0].tier})</h2>
                        <div className='containerPlayers'>
                            {joueur.map(objet => (
                                <div key={objet.nom}>
                                    <p>{objet.nom}</p>
                                    <img src={objet.image} alt={objet.nom} className='imgObject' />
                                    
                                </div>
                            ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
      );
}